import React, { useContext } from 'react';
import ReactPaginate         from 'react-paginate';
import { useIsFetching }     from 'react-query';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import { StoreContext } from '../../context/Store';
import * as S           from './styles';

const Pagination = () => {
  const { meta, page, setPage } = useContext(StoreContext);
  const isFetching = useIsFetching();

  const onSelectPage = (e) => {
    const grid = document.getElementById('int-grid');
    window.scrollTo({
      top: grid.offsetTop - 160,
      behavior: 'smooth'
    });
    setPage(e.selected);
  }

  if (isFetching) {
    return null;
  }

  return (
    <Wrapper>
      {meta &&
      <div>
        {meta.page_count > 1 &&
        <>
          <S.Pagination>
            <ReactPaginate
              previousLabel={'Vorherige'}
              nextLabel={'Nächste'}
              breakLabel={'...'}
              pageCount={meta.page_count}
              onPageChange={e => onSelectPage(e)}
              forcePage={page}
            />
          </S.Pagination>
          <Spacer/>
        </>
        }
        <S.Counts>
          <p>{meta.result_count} von {meta.filter_count} gefundenen Produkten</p>
        </S.Counts>
      </div>
      }
    </Wrapper>
  )
};

export default Pagination;