import React                 from 'react';
import Rating                from 'react-rating';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { Tooltip }           from '@chakra-ui/react';
import ConditionalWrapper    from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';

const RatingStars = ({ rating, count, showTooltip }) => {
  return (
    <ConditionalWrapper condition={showTooltip} wrapper={children => <Tooltip label={`Basierend auf ${count} Bewertungen`}>{children}</Tooltip>}>
      <span>
        <Rating initialRating={rating}
                readonly
                emptySymbol={<FaRegStar fill="#ffc107"/>}
                fullSymbol={<FaStar fill="#ffc107"/>}/>
      </span>
    </ConditionalWrapper>
  )
};

RatingStars.defaultProps = {
  showTooltip: false,
}

export default RatingStars;