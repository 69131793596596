import React             from 'react';
import { readableColor } from 'polished';
import { Select }        from 'chakra-react-select';
import { useTheme }      from '@chakra-ui/react';

const MultiSelect = ({ title, name, options, onChangeFilter }) => {

  const chakraTheme = useTheme();

  return <Select
    aria-label={title}
    isMulti
    isSearchable={false}
    name={name}
    placeholder={title}
    noOptionsMessage={() => <p>Keine weiteren Filter verfügbar</p>}
    options={options}
    formatOptionLabel={o => o.display_name}
    getOptionLabel={o => o.display_name}
    getOptionValue={o => o}
    onChange={values => onChangeFilter(values)}
    isDisabled={options.length === 0}
    chakraStyles={{
      multiValue: provided => ({
        ...provided,
        bg: chakraTheme.colors.base['700'],
        color: readableColor(chakraTheme.colors.base['700'])
      }),
      placeholder: provided => ({
        ...provided,
        color: readableColor(chakraTheme.colors.base['500'])
      }),
      menuList: provided => ({
        ...provided,
        bg: 'var(--chakra-colors-base-500)'
      }),
      option: provided => ({
        ...provided,
        color: readableColor(chakraTheme.colors.base['500']),
        bg: chakraTheme.colors.base['500'],
        '&:hover': {
          bg: chakraTheme.colors.base['700']
        }
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        bg: 'transparent',
        px: 2,
        cursor: 'inherit'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      })
    }}
  />
};

export default MultiSelect;