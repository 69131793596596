import React, { useState }                      from 'react';
import { graphql }                              from 'gatsby';
import Img                                      from 'gatsby-image';
import { useTheme }                             from '@chakra-ui/react';
import { Container, Row, Col }                  from 'react-grid-system'
import styled                                   from '@emotion/styled';
import AnimateHeight                            from 'react-animate-height';
import Breakpoint, { useCurrentBreakpointName } from 'react-socks';
import HTMLEllipsis                             from 'react-lines-ellipsis/lib/html'
import { FaChevronDown, FaChevronUp }           from 'react-icons/fa'
import { readableColor }                        from 'polished';


import { findT }        from '@interness/web-core/src/components/_helpers';
import SEO              from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                        from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper          from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Logo             from '@interness/web-core/src/components/media/Logo/Logo';
import Carousel         from '@interness/web-core/src/components/media/Carousel/Carousel';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Overridable      from '@interness/web-core/src/components/Overridable/Overridable';
import BrandPageWrapper from '@interness/ecommerce-addon/src/components/BrandPageWrapper/BrandPageWrapper';
import Mounted          from '@interness/web-core/src/components/structure/Mounted/Mounted';

import FramedImage from '../../../../components/FramedImage';
import CallToAction from '../../../../components/CallToAction/CallToAction';

const TextCol = styled.div`
  text-align: justify;
`;

const BtnContainer = styled.div`
  text-align: center;
  margin: 20px 0;

  button {
    color: ${props => readableColor(props.theme.base_color)};
  }

  svg {
    vertical-align: text-top;
    margin-left: 10px;
  }
`;

const BrandPageTemplate = props => {
  const theme = useTheme();
  const breakpoint = useCurrentBreakpointName();
  const metaData = props.data.allSitePlugin.nodes;
  const data = props.data.directusBrands.brand;
  const type = props.data.directusBrandTypes;
  const translations = findT(data.translations, props.pageContext.lang);
  const typeTranslations = findT(type.translations, props.pageContext.lang);
  const [showLongDescrOnMobile, toggleDescr] = useState(false);
  const toggleMore = () => {
    toggleDescr(!showLongDescrOnMobile);
  };

  let hasEcom = metaData.filter(plugin => plugin.name === '@interness/ecommerce-addon');
  hasEcom = hasEcom.length === 1;

  const category = typeTranslations.slug.charAt(0).toUpperCase() + typeTranslations.slug.slice(1);

  return (
    <>
      <SEO title={data.display_name}/>
      <Mounted>
        <BrandsSubnavigation type={data.type.type}/>
      </Mounted>
      <Spacer/>
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={6}>
              <div style={{ textAlign: 'center' }}>
                <Logo logo={data.logo.localFile} title={data.display_name} svgWidth="240px" svgHeight="80px"/>
              </div>
            </Col>
            <Col md={6}/>
          </Row>
          <Row>
            <Col md={6}>
              <Breakpoint large down>
                <AnimateHeight height={!showLongDescrOnMobile ? 'auto' : 0}>
                  <div style={{ margin: '20px 0 0 0', textAlign: 'justify' }}>
                    <Overridable fieldName="translations.description" collection="brand" itemId={data.directus_id}
                                 type="html_override"
                                 render={override => {
                                   return <HTMLEllipsis unsafeHTML={override ? override : translations.description}
                                                        maxLine={5} ellipsis={'...'}/>;
                                 }}/>
                  </div>
                </AnimateHeight>
                <BtnContainer>
                  <button onClick={toggleMore}>
                    Mehr über {data.display_name}
                    {showLongDescrOnMobile ? <FaChevronUp/> : <FaChevronDown/>}
                  </button>
                </BtnContainer>
              </Breakpoint>
              <AnimateHeight height={breakpoint === 'xlarge' || showLongDescrOnMobile ? 'auto' : 0}>
                <TextCol>
                  <Overridable fieldName="translations.description" collection="brand" itemId={data.directus_id}
                               type="html_override"
                               render={override => {
                                 return <div
                                   dangerouslySetInnerHTML={{ __html: override ? override : translations.description }}/>;
                               }}/>
                </TextCol>
              </AnimateHeight>
            </Col>
            <Col md={6}>
              <FramedImage borderOnGatsbyImage sx={{
                'img, figure': {
                  margin: 0,
                },
                '.int-custom .slick-dots li button': {
                  backgroundColor: theme.colorMode === 'light' ? '#333' : '#fff',
                },
                '.int-custom .slick-dots li.slick-active button': {
                  backgroundColor: theme.brand_color,
                }
              }}>
                <Carousel>
                  {data.gallery_images_square.map((image, i) => {
                    image = image.file;
                    return (
                      <figure key={image.localFile.name}>
                        <Img fluid={image.localFile.childImageSharp.fluid} alt={image.localFile.name} loading={i === 0 ? 'eager' : 'lazy'}/>
                        {image.description &&
                          <figcaption dangerouslySetInnerHTML={{ __html: image.description }}/>}
                      </figure>
                    );
                  })}
                </Carousel>
              </FramedImage>
            </Col>
          </Row>
          <Spacer/>
          <Row>
            <Col md={12}>
              {data.youtube_video_id &&
                <>
                  <ResponsiveIFrame
                    src={`https://www.youtube-nocookie.com/embed/${data.youtube_video_id}?rel=0&amp;controls=0`}
                    title={`YoutubeVideo`} type={'youtube'}/>
                  <Spacer/>
                </>}
              {data.vimeo_video_id &&
                <>
                  <ResponsiveIFrame maxHeight={56.25}
                                    src={`https://player.vimeo.com/video/${data.vimeo_video_id}?dnt=1`}
                                    title={`VimeoVideo`} type={'vimeo'}/>
                  <Spacer/>
                </>}
            </Col>
          </Row>
        </Container>
        {hasEcom && <div>
          <BrandPageWrapper category={typeTranslations.slug} title={`${data.display_name} ${category}`}
                            manufacturer={data.slug}/>
        </div>}
        <CallToAction />
      </Wrapper>
    </>
  )
};

export default BrandPageTemplate;

export const query = graphql`
    query($slug: String!, $type: String!) {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
            ...BrandGalleryImagesSquare
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                slug
                language
            }
        }
        allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
            nodes {
                version
                name
            }
        }
    }
`;