import currency from 'currency.js';

// const MWST_16 = true;

const useTaxValue = (price, tax_percent) => {
  const price_netto = currency(price).divide(parseFloat('1.' + tax_percent)).value;
  const tax = currency(price).subtract(price_netto).value;
  // const price_brutto_16 = currency(price_netto).multiply(1.16).value;


  return {
    taxPercentage: tax_percent,
    taxValue: tax,
    priceNetto: price_netto,
    priceBrutto: currency(price).value
  }
};

export default useTaxValue;