import React, { useContext, useEffect } from 'react';
import { Select, useTheme }             from '@chakra-ui/react';
import { FaSortAmountDown }             from 'react-icons/fa';
import { readableColor }                from 'polished';

import { StoreContext } from '../../context/Store';

const Sort = ({ defaultSort }) => {
  const { sort, setSort } = useContext(StoreContext);
  const chakraTheme = useTheme();

  const sortOptions = [
    {
      label: 'Neuste zuerst',
      value: '-created_on'
    },
    {
      label: 'Älteste zuerst',
      value: 'created_on'
    },
    {
      label: 'Preis aufsteigend',
      value: 'price_brutto'
    },
    {
      label: 'Preis absteigend',
      value: '-price_brutto'
    },
    {
      label: 'Zufällig',
      value: '?'
    }
  ]

  useEffect(() => {
    if (sort.length === 0) {
      setSort(defaultSort);
    }
    //eslint-disable-next-line
  }, [sort])

  const onChangeSort = (value) => {
    setSort(value)
  };

  return (
    <>
      <Select
        aria-label="Sortierung"
        defaultValue={defaultSort}
        onChange={e => onChangeSort(e.target.value)}
        icon={<FaSortAmountDown/>}
        color={readableColor(chakraTheme.colors.base['500'])}
        sx={{
          color: readableColor(chakraTheme.colors.base['500']),
        }}
      >
        {sortOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
      </Select>
    </>
  )
};

export default Sort;