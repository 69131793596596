import React, { useContext }   from 'react';
import { navigate }            from 'gatsby';
import { Img }                 from 'react-image';
import { Skeleton, Box, Text } from '@chakra-ui/react';

import { endpoints }    from '../../config';
import * as S           from './styles';
import { StoreContext } from '../../context/Store';
import PriceTag         from '../PriceTag';
import RatingStars      from '../ProductRatings/RatingStars';
import {
  calculateAverageRating,
  getVariantsPriceRange
}                       from '../../helpers';
import Availability     from '../Availability';

const GridItem = ({ product }) => {
  const { storeRootPath } = useContext(StoreContext);

  const onNavigate = () => {
    navigate(`${storeRootPath}/${product.category.slug}/${product.slug}/${product.id}`, { state: product });
  };

  return (
    <S.GridItem onClick={() => onNavigate()}>
      <div className="int-image-container">
        {product.images.length === 0
          ? <Img src="https://placehold.jp/333/ffffff/200x200.png?text=Kein%20Bild" alt="Kein Bild"
                 loader={<Skeleton height="200px"/>}/>
          : <Img
            src={`${endpoints.thumbnails}/${product.images.filter(image => !!image.file_id)[0].file_id.private_hash}?key=e-grid-thumbnail`}
            alt={product.display_name}
            unloader={<img src="https://placehold.jp/333/ffffff/200x200.png?text=Kein%20Bild" alt="Kein Bild"/>}
            loader={<Skeleton height="200px"/>}/>
        }
      </div>
      <Box p={2}>
        {product.ratings.length > 0 &&
        <RatingStars rating={calculateAverageRating(product.ratings)} count={product.ratings.length} showTooltip/>}
        {product.manufacturer && <p className="int-product-manufacturer"
                                    title={product.manufacturer.display_name}>{product.manufacturer.display_name}</p>}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Availability product={product} asBubble />
          <p className="int-product-name" style={{marginLeft: '5px'}} title={product.display_name}>{product.display_name}</p>
        </div>
        <Text color="brand.500" fontWeight="bold" pt={2}>
          {product.product_variants.length > 0
            ? <PriceTag priceRange={getVariantsPriceRange(product.product_variants)} showInfo={false}/>
            : <PriceTag price={product.price_brutto} showInfo={false}/>
          }
        </Text>
      </Box>
    </S.GridItem>
  );
};

export default GridItem;
