import styled     from '@emotion/styled';
import { darken } from 'polished';

export const GridItem = styled.div`
  padding: 15px 0;
  border-radius: 3px;
  text-align: center;
  transition: all 0.1s linear;
  cursor: pointer;
  //background-color: var(--chakra-colors-base-100);
  background-color: var(--chakra-colors-base-600);
  &:hover {
    //box-shadow: 0 0 15px ${props => darken(0.15, props.theme.base_color)};
    transform: scale(1.02);
  }
  p {
    margin: 0;
  }
  .int-image-container {
    text-align: center;
    margin: auto;
    width: 200px;
    height: 200px;
  }
  .int-product-manufacturer {
    margin-top: 10px;
    font-size: 0.8rem;
    max-width: 200px;
    font-weight: lighter;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .int-product-name {
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
  }
  .int-product-price {
    margin-top: 10px;
    font-weight: bold;
  }
  img {
    margin-bottom: 0;
  }
`;
