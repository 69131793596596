import styled                    from '@emotion/styled';
import { readableColor, darken } from 'polished';

export const FilterWrapper = styled.div`
  z-index: 2;
  position: relative;
  //Todo 
  //top: 88px;
  //@media (max-width: 1024px) {
  //  position: relative;
  //  top: 0;
  //}
  //background-color: var(--chakra-colors-base-200);
  a, button {
    color: ${props => readableColor(props.theme.base_color)} !important;
  }
`;

export const FilterInner = styled.div`
  width: 100%;
  min-height: 80px;
  //background: #fff;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;

export const FilterBar = styled.div`
    //background-color: ${props => props.theme.brand_color};
  border-top: 1px solid var(--chakra-colors-gray-200);
  border-bottom: 1px solid var(--chakra-colors-gray-200);
`;

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    min-width: 250px;
    margin: 8px 10px 8px 0;
    flex-grow: 1;
    // div {
    //   color: ${props => readableColor(props.theme.base_color)} !important;
    // }
  }
`;

export const SortSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  > * {
    margin: 4px auto 8px auto;
    flex-grow: 1;
  }
`;

export const CategoryBar = styled.nav`
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 0 auto;
  //border-bottom: 1px solid lightgray;
`;

export const CategoryTab = styled.button`
  background: unset;
  padding: 10px 20px;
  margin: 0 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &.current {
    border-bottom: 3px solid ${props => props.theme.brand_color};
  }

  &:hover {
    background-color: ${props => darken(0.1, props.theme.base_color)};
  }
`