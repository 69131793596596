import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import Loader                from 'react-loader-spinner';
import styled                from '@emotion/styled';
import { ThemeContext }      from '@emotion/react';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Spinner = ({ size, color }) => {
  const { brand_color } = useContext(ThemeContext);
  return <Loader type="Oval" color={color ? color : brand_color} width={size} height={size}/>
};

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  size: 80,
};

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
`;

export default Spinner;
