import React, { useContext } from 'react';
import { Badge, Text }       from '@chakra-ui/react';
import { FaCircle }          from 'react-icons/fa';

import { StoreContext } from '../context/Store';


const Availability = ({ product, asBubble = false }) => {
  const { settings, currentProduct } = useContext(StoreContext);
  const useProduct = product ? product : currentProduct;
  const { stock, reorderPossible } = useProduct;
  const canReorder = settings.data.purchase_to_order && reorderPossible;

  if (asBubble) {
    if (stock >= 5) {
      return <FaCircle style={{ width: '12px', height: '12px' }}
                       fill="#27ae60"/>
    }
    if (stock < 5 && stock > 0) {
      return <FaCircle style={{ width: '12px', height: '12px' }} fill="#27ae60"/>
    }
    if (stock <= 0) {
      if (canReorder) {
        return <FaCircle style={{ width: '12px', height: '12px' }} fill="#f1c40f"/>
      } else {
        return <FaCircle style={{ width: '12px', height: '12px' }} fill="#e74c3c"/>
      }
    }
  } else {
    // TODO Individualisierungen

    if (useProduct.hasVariants && !useProduct.variantSelected) {
      return <Text>Bitte Variante wählen</Text>
    }

    if (stock >= 5) {
      return <Badge colorScheme="green">Aktuell Verfügbar</Badge>
    }
    if (stock < 5 && stock > 0) {
      return <Badge colorScheme="green">Aktuell Verfügbar</Badge>
    }
    if (stock <= 0) {
      if (canReorder) {
        return <Badge colorScheme="yellow">Auf Bestellung</Badge>
      } else {
        return <Badge colorScheme="red">Aktuell nicht verfügbar</Badge>
      }
    }
  }
};

export default Availability;
