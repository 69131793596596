import React, { useState, useContext, useEffect } from 'react';
import { graphql, useStaticQuery, navigate }      from 'gatsby';
import { VisuallyHidden }                         from '@chakra-ui/react'

import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading   from '@interness/web-core/src/components/text/Heading/Heading';
import { findT } from '@interness/web-core/src/components/_helpers';
import Center    from '@interness/web-core/src/components/structure/Center/Center';

import Filters          from '../Filters/Filters';
import ProductGrid      from '../ProudctGrid/ProductGrid';
import Pagination       from '../Pagination/Pagination';
import { EApiContext }  from '../../context/EApi';
import { StoreContext } from '../../context/Store';
// import FiltersSidebar   from '../Filters/FiltersSidebar';

const Overview = ({ title, brandPageMode, tag }) => {
  // const [sidebarEnabled, setSidebarEnabled] = useState(false);
  const { settings } = useContext(EApiContext);
  const { storeRootPath, } = useContext(StoreContext);
  // const { loading, data: settingsData, error } = settings;
  const { loading, error } = settings;
  const data = useStaticQuery(query);

  useEffect(() => {
    if (error) {
      if (!brandPageMode) {
        navigate(storeRootPath + '/error');
      }
    } else {
      // if (!loading && settingsData.filters) {
      //   setSidebarEnabled(settingsData.filters.includes('attributes'));
      // }
    }
    //eslint-disable-next-line
  }, [settings, loading, error])

    return (
      <div>
        {!brandPageMode
          ? <>
            <Spacer/>
            <Heading subtitle={`${findT(data.directusProject.translations, 'de').seo_title}`}>{title}</Heading>
          </>
          : <>
            <Heading subtitle={`bei ${findT(data.directusProject.translations, 'de').seo_title}`}>{title}</Heading>
          </>
        }
        <Filters brandPageMode={brandPageMode}/>
        {tag && <>
          <Spacer height={20}/>
          <Center><p>Schlagwort: <b>{tag}</b></p></Center>
        </>}
        <ProductGrid tag={tag}/>
        {/*<div style={{ display: 'flex' }}>*/}
        {/*  {sidebarEnabled && <FiltersSidebar/>}*/}
        {/*  <div style={{ width: sidebarEnabled ? '80%' : '100%' }}>*/}
        {/*    <ProductGrid manufacturer={manufacturer} category={category} setRender={setRender} brandPageMode={brandPageMode}/>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Spacer/>
        <Pagination/>
        <Spacer/>
      </div>
    );
};

export default Overview;

Overview.defaultProps = {
  brandPageMode: false,
}

const query = graphql`
    query {
        directusProject {
            display_name
            translations {
                seo_title
                language
            }
        }
    }
`;