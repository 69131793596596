import React, { useContext, useEffect, useState } from 'react';
import { useTheme }                               from '@chakra-ui/react';

import { StoreContext } from '../../context/Store';
import MultiSelect      from './MultiSelect';

const SubcategoriesFilter = ({ options }) => {
  const { categoryFilter, setSubcategoriesFilter } = useContext(StoreContext);
  const [possibleOptions, setPossibleOptions] = useState(options);

  useEffect(() => {
    if (categoryFilter !== null) {
      const filteredOptions = options.map(option => {
        if (!option.subcategory_id.category) {
          return option
        } else {
          const inCategory = option.subcategory_id.category.id === categoryFilter;
          if (inCategory) {
            return option;
          }
          return null;
        }
      });

      setPossibleOptions(filteredOptions.filter(e => e !== null));
    } else {
      setPossibleOptions(options);
    }

    return async () => {
      await setSubcategoriesFilter([])
    }

  }, [categoryFilter, options])

  const onChangeFilter = (values) => {
    setSubcategoriesFilter(values);
  }

  return (
    <>
      <MultiSelect
        title={'Unterkategorie'}
        name={'subcategoriesFilter'}
        options={possibleOptions.map(option => option.subcategory_id)}
        onChangeFilter={values => onChangeFilter(values)}
      />
    </>
  )
};

export default SubcategoriesFilter;