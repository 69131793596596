import React, { useContext } from 'react';

import { StoreContext }             from '../../context/Store';
import { CategoryBar, CategoryTab } from './styles';

const CategoryFilter = ({ options }) => {
  const { categoryFilter, setCategoryFilter, setManufacturersFilter, setSubcategoriesFilter } = useContext(StoreContext);

  const onClickTab = (value) => {
    setCategoryFilter(value);
    setManufacturersFilter([]);
    setSubcategoriesFilter([])
  }

  return (
    <CategoryBar>
      <CategoryTab onClick={() => onClickTab(null)}
                   className={categoryFilter === null ? 'current' : null}>Alle</CategoryTab>
      {options.map(filter => <CategoryTab key={filter.category_id.id}
                                          onClick={() => onClickTab(filter.category_id.id)}
                                          className={categoryFilter === filter.category_id.id ? 'current' : null}>{filter.category_id.display_name}</CategoryTab>)}
    </CategoryBar>
  )
};

export default CategoryFilter;