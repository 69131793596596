import React, { useContext, useEffect } from 'react';
import { usePaginatedQuery }            from 'react-query';
import { SimpleGrid, Box }              from '@chakra-ui/react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import GridItem         from '../GridItem/GridItem';
import { StoreContext } from '../../context/Store';
import { EApiContext }  from '../../context/EApi';
import Error            from '../Error/Error';

const ProductGrid = ({ tag }) => {
  const {
    categoryFilter,
    manufacturersFilter,
    subcategoriesFilter,
    sort,
    setMeta,
    page,
  } = useContext(StoreContext);

  const { fetchProducts } = useContext(EApiContext);

  const { isIdle, isLoading, isError, resolvedData, latestData } = usePaginatedQuery(['products', {
    categoryFilter,
    manufacturersFilter,
    subcategoriesFilter,
    sort,
    tagFilter: tag,
  }, page], fetchProducts, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
    enabled: sort.length !== 0
  });

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (resolvedData) {
        setMeta(resolvedData.meta);
      }
    }
    return () => {
      mounted = false;
    }
    //eslint-disable-next-line
  }, [resolvedData])

  if (isError) {
    return <Error>Fehler beim Laden der Produkte.</Error>
  }

  if (isIdle || isLoading || !latestData) {
    return <div><Spacer/><SpinnerContainer><Spinner/></SpinnerContainer></div>
  }

  if (resolvedData.data.length === 0) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
      Keine Produkte gefunden
    </div>
  }

  if (resolvedData) {
    return (
      <Box>
        <Wrapper>
          <Spacer/>
          <SimpleGrid minChildWidth="250px" spacing={5} id={'int-grid'}>
            {resolvedData.data.map(product => <GridItem key={product.id} product={product}/>)}
          </SimpleGrid>
        </Wrapper>
      </Box>
    )
  }
};

export default ProductGrid;
