import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../context/Store';
import MultiSelect      from './MultiSelect';

const ManufacturersFilter = ({ options }) => {
  const { categoryFilter, setManufacturersFilter } = useContext(StoreContext);
  const [possibleOptions, setPossibleOptions] = useState(options);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (categoryFilter !== null) {
        const filteredOptions = options.map(option => {
          if (option.manufacturer_id.categories.length === 0) {
            return option;
          } else {
            const inCategory = option.manufacturer_id.categories.filter(category => category.category_id.id === categoryFilter).length >= 1;
            if (inCategory) {
              return option;
            }
            return null;
          }
        });

        setPossibleOptions(filteredOptions.filter(e => e !== null));
      } else {
        setPossibleOptions(options);
      }
    }

    return () => {
      mounted = false
    }

  }, [categoryFilter, options])

  const onChangeFilter = (values) => {
    setManufacturersFilter(values);
  }

  return (
    <>
      <MultiSelect
        title={'Hersteller'}
        name="manufacturersFilter"
        options={possibleOptions.map(option => option.manufacturer_id)}
        onChangeFilter={values => onChangeFilter(values)}
      />
    </>
  )
};

export default ManufacturersFilter;