import React, { useContext } from 'react';
import { FaInfoCircle }      from 'react-icons/fa';
import currency              from 'currency.js';
import { Tooltip }           from '@chakra-ui/react';

import useTaxValue      from '../hooks/useTaxValue';
import { StoreContext } from '../context/Store';

const PriceTag = ({ price, priceRange, showMwst, showInfo, priceNetto, replaceZero }) => {
    const { settings } = useContext(StoreContext);
    const { taxPercentage, taxValue, priceBrutto } = useTaxValue(price, settings.data.default_tax_percent);

    if (settings.data.default_tax_percent === 0) {
      showInfo = false;
    }

  if (replaceZero && price === 0) {
    return <>Preis auf Anfrage</>
  }

    if (!price && priceRange) {
      return <>
        {priceRange.map((price, index) => {
          return (
            <React.Fragment key={price}>
              {currency(price, {
                symbol: '€',
                separator: '.',
                decimal: ',',
                pattern: '# !'
              }).format()}
              {priceRange.length > 1 && index === priceRange.length - 2 && <>&nbsp;-&nbsp;</>}
            </React.Fragment>
          );
        })}
        {showInfo && <>
          <Tooltip label={`Inkl. ${taxPercentage}% Mehrwertsteuer`}>
            <span>
              <FaInfoCircle style={{ verticalAlign: 'middle', marginLeft: '10px' }}/>
            </span>
          </Tooltip>
        </>}
      </>
    }

    if (typeof priceNetto === 'number') {
      return <>{currency(priceNetto, { symbol: '€', separator: '.', decimal: ',', pattern: '# !' }).format()}</>
    }

    if (price && showMwst) {
      return <>
        <>{currency(taxValue, { symbol: '€', separator: '.', decimal: ',', pattern: '# !' }).format()}</>
        {showInfo && <>
          <Tooltip label={`${taxPercentage}%`}>
            <span>
              <FaInfoCircle style={{ verticalAlign: 'middle', marginLeft: '10px' }}/>
            </span>
          </Tooltip>
        </>}
      </>
    } else {
      return (
        <><>{currency(priceBrutto, { symbol: '€', separator: '.', decimal: ',', pattern: '# !' }).format()}</>
          {showInfo && <>
            <Tooltip label={`Inkl. ${taxPercentage}% Mehrwertsteuer`}>
            <span>
              <FaInfoCircle style={{ verticalAlign: 'middle', marginLeft: '10px' }}/>
            </span>
            </Tooltip>
          </>}
        </>
      )
    }
  }
;

PriceTag.defaultProps = {
  showInfo: true,
  replaceZero: true,
}

export default PriceTag;