import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import { navigate }          from 'gatsby';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import { EApiContext }     from '../../context/EApi';
import CategoryFilter      from './CategoryFilter';
import ManufacturersFilter from './ManufacturersFilter';
import SubcategoriesFilter from './SubcategoriesFilter';
import Sort                from './Sort';
// import PriceRangeFilter    from './PriceRangeFilter';
import {
  FilterWrapper,
  FilterBar,
  FilterInner,
  FilterSection,
  SortSection
}                          from './styles';
import { StoreContext }    from '../../context/Store';

const FilterRenderer = ({ filterName, data, brandPageMode }) => {
  switch (filterName) {
    case 'manufacturer':
      if (!brandPageMode) {
        return <ManufacturersFilter options={data.manufacturer_filters}/>;
      } else {
        return null;
      }
    case 'subcategory':
      return <SubcategoriesFilter options={data.subcategory_filters}/>;
    // case 'price':
    //   return <PriceRangeFilter/>;
    case 'free':
      return <>...Freitextsuche...</>;
    default:
      return null;
  }
}

const Filters = ({ brandPageMode }) => {
  const { settings } = useContext(EApiContext);
  const { storeRootPath } = useContext(StoreContext);
  const { loading, data, error } = settings;

  if (loading) {
    return null;
  }

  if (error) {
    if (!brandPageMode) {
      navigate(storeRootPath + '/error');
    }
    return null;
  }

  return (
    <FilterWrapper>
      {!brandPageMode && data.filters.includes('category') && <CategoryFilter options={data.category_filters}/>}
      <FilterBar>
        <Wrapper>
          <FilterInner>
            <FilterSection>
              {data.filters.map(filter => <FilterRenderer key={filter} filterName={filter} data={data}
                                                          brandPageMode={brandPageMode}/>)}
            </FilterSection>
            <SortSection>
              <Sort defaultSort={data.default_sort}/>
            </SortSection>
          </FilterInner>
        </Wrapper>
      </FilterBar>
    </FilterWrapper>
  )
};

Filters.propTypes = {
  brandPageMode: PropTypes.bool,
}

Filters.defaultProps = {
  brandPageMode: false,
}

export default Filters;